import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams, StringParam } from 'use-query-params';
import { Row, Menu, notification, message, Dropdown } from 'antd';
import OutsideClickHandler from 'react-outside-click-handler';

import { useFetch } from 'services/hooks';
import { useAuth } from 'hooks/auth';
import { useComponentNextWithTwoCursors } from 'components/List';

import PageTitle from 'components/PageTitle';
import Button from 'components/Button';

import LivenessCard from './components/LivenessCard';
import EmptyPage from './components/EmptyPage';

// import './styles.less';

const LivenessList = () => {
  const { t: translate } = useTranslation();
  const { get } = useFetch();
  const [exportStatus, setExportStatus] = useState({
    loading: false,
    error: false,
  });
  const [isDropdownExportOpened, setIsDropdownExportOpened] = useState(false);
  const { user } = useAuth();

  const { data, componentList, updateParams, loading } = useComponentNextWithTwoCursors({
    component: LivenessCard,
    rowKey: '_id',
    url: 'faceliveness',
    queryParams: {
      _filter: StringParam,
      isAlive: StringParam,
      createdAt: StringParam,
    },
  });

  const [query] = useQueryParams({
    _filter: StringParam,
    isAlive: StringParam,
    _order: StringParam,
    _sort: StringParam,
    createdAt: StringParam,
  });

  const mapFilters = useCallback(() => {
    const filters = {};

    if (query.createdAt) {
      switch (query.createdAt) {
        case 'last24Hours':
          filters._startCreatedAt = new Date().getTime() - 24 * 60 * 60 * 1000;
          filters._endCreatedAt = new Date().getTime();
          break;
        case 'last7Days':
          filters._startCreatedAt = new Date().getTime() - 24 * 60 * 60 * 1000 * 7;
          filters._endCreatedAt = new Date().getTime();
          break;
        case 'last30Days':
          filters._startCreatedAt = new Date().getTime() - 24 * 60 * 60 * 1000 * 30;
          filters._endCreatedAt = new Date().getTime();
          break;
        case 'lastYear':
          filters._startCreatedAt = new Date().setFullYear(new Date().getFullYear() - 1);
          filters._endCreatedAt = new Date().getTime();
          break;
        default:
          if (query.createdAt.substr(0, 6) === 'custom') {
            const [startDate, endDate] = query.createdAt.substr(7).split('-');
            filters._startCreatedAt = startDate;
            filters._endCreatedAt = endDate;
          }
          break;
      }
      filters._startCreatedAt = filters._startCreatedAt
        ? new Date(parseInt(filters._startCreatedAt, 10))
        : null;
      filters._endCreatedAt = filters._endCreatedAt
        ? new Date(parseInt(filters._endCreatedAt, 10))
        : null;
    }

    if (query._sort && query._order) {
      filters._sort = query._sort;
      filters._order = query._order;
    }

    filters._filter = query._filter;
    filters.isAlive = query.isAlive;
    return filters;
  }, [query]);

  useEffect(() => {
    updateParams({
      url: 'faceliveness',
      config: {
        params: { ...mapFilters() },
      },
    });
  }, [query]); // eslint-disable-line

  const onExportList = useCallback(async () => {
    try {
      setIsDropdownExportOpened(false);
      setExportStatus({ loading: true, error: false });

      await get({
        url: 'faceliveness/export',
        config: {
          params: {
            ...mapFilters(),
          },
        },
      });

      const notificationArgs = {
        type: 'success',
        message: translate(
          'pages.private.profiles.components.list.index.onExportList.notificationArgs.message'
        ),
        description: translate(
          'pages.private.profiles.components.list.index.onExportList.notificationArgs.description'
        ),
        duration: 4.5,
        className: 'custom-notification',
      };

      notification.open(notificationArgs);
      setExportStatus({ loading: false, error: false });
    } catch (error) {
      message.error(
        translate(
          'pages.private.profiles.components.list.index.onExportList.message.error'
        )
      );

      setIsDropdownExportOpened(false);
      setExportStatus({ loading: false, error: true });
    }
  }, [get, mapFilters, translate]);

  const menuExport = useMemo(
    () => (
      <>
        <Menu className="menu-export-list">
          <Menu.Item key="csv_profiles">
            <button
              type="button"
              disabled={data?.totalItems === 0 || loading}
              className="menu-export-list-btn reset-btn"
              onClick={() => onExportList()}
            >
              <i className="caf-ic_c_download" />
              <span>
                {translate(
                  'pages.private.liveness.components.list.menuExport.title.liveness'
                )}
              </span>
            </button>
          </Menu.Item>
        </Menu>
      </>
    ),
    [onExportList, data, translate, loading]
  );

  return (
    <div id="profiles-list-component">
      <Row align="middle" justify="space-between" className="no-mrg">
        <PageTitle
          title={translate(
            'pages.private.liveness.livenessTab.components.list.pageTitle.title'
          )}
          subtitle={translate(
            'pages.private.liveness.livenessTab.components.list.pageTitle.subtitle'
          )}
        />
        {user?.accountData?.featureFlags?.faceLivenessExport ? (
          <OutsideClickHandler
            onOutsideClick={() => setIsDropdownExportOpened(false)}
            disabled={!isDropdownExportOpened}
          >
            <Dropdown
              overlay={menuExport}
              trigger={['click']}
              visible={isDropdownExportOpened}
              placement="bottomRight"
            >
              <Button
                type="default"
                shape="circle"
                loading={exportStatus.loading}
                className={`export-list-btn ${exportStatus.error && 'danger'}`}
                onClick={() => setIsDropdownExportOpened((oldState) => !oldState)}
              >
                {!exportStatus.loading && <i className="caf-ic_c_download" />}
              </Button>
            </Dropdown>
          </OutsideClickHandler>
        ) : (
          <></>
        )}
      </Row>

      <div className="list-content">
        {data?.totalItems === 0 ? <EmptyPage /> : componentList}
      </div>
    </div>
  );
};

export default LivenessList;
