import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'antd';

import EmptyState from 'components/EmptyState';
import EmptyExecutionState from 'components/EmptyExecutionState';

import ContactAndRelationCard from 'pages/private/Profiles/components/ContactAndRelationCard';
import DataSourceCardCollapsePanel from 'components/DataSourceCards/DataSourceCardCollapsePanel';

const I18N_BASE_PATH =
  'components.dataSourceCards.basicInfos.components.person.contactContent.contactContent.basicInfosCard';

export const PfPhones = ({ source = {}, toPrint = false, type = 'default' }) => {
  const { t: translate } = useTranslation();

  const [isPhonesPanelOpened, setIsPhonesPanelOpened] = useState(false);

  const pfPhones = useMemo(() => source, [source]);
  const statusCode = useMemo(() => source?.data?.statusCode || source?.statusCode, [
    source,
  ]);

  const phones = useMemo(() => {
    if (!pfPhones) return 'no-recurrence';
    const data = pfPhones?.data?.phones || pfPhones?.data || [];

    // adiciona do statusCode === '02' para tratar o caso de não ter sido possível consultar
    if (
      statusCode === '02' ||
      (!['00', '01'].includes(statusCode) &&
        data.length <= 0 &&
        !pfPhones?.available &&
        !pfPhones?.lastConsultation)
    )
      return 'query-failed';
    if (data.length <= 0) return 'no-data';

    return data;
  }, [pfPhones, statusCode]);

  const onClickHandler = useCallback(() => {
    setIsPhonesPanelOpened((oldState) => !oldState);
  }, []);

  const parsedPhonesList = useMemo(() => {
    if (!Array.isArray(phones) || !phones?.length > 0) return <></>;

    const el = [];
    const _size = phones?.length;

    if (_size) {
      for (let i = 0; i < _size; i += 3) {
        el.push(
          <Row className="contact-data-row">
            {phones?.slice(i, i + 3)?.map((item) => (
              <ContactAndRelationCard
                key={item?.number}
                span={8}
                type={type === 'alt' ? 'PHONE' : item?.type}
                content={`(${item?.areaCode || '-'}) ${item?.number || '-'}`}
                className="pdd-vertical-10 pdd-horizontal-0"
              />
            ))}
          </Row>
        );
      }
    }

    if (el.length > 0) {
      const parsedEl = isPhonesPanelOpened ? el : el.splice(0, 1);
      return parsedEl;
    }

    return <>-</>;
  }, [phones, type, isPhonesPanelOpened]);

  return (
    <DataSourceCardCollapsePanel
      icon="caf-ic_contact_"
      title={
        type === 'alt'
          ? translate(`${I18N_BASE_PATH}.phonesAlt.title`)
          : translate(`${I18N_BASE_PATH}.phones.title`)
      }
      dataIndex="contacts"
      customQuery={false}
      customId="basic-infos-contacts"
      toPrint={toPrint}
    >
      <Row className="contact-data-container gx-w-100">
        {pfPhones && (
          <Col span={24} className="contact-data-col">
            {phones === 'no-data' && (
              <EmptyState
                type="empty-success"
                description={translate(`${I18N_BASE_PATH}.phones.emptyState.description`)}
              />
            )}

            {phones === 'query-failed' && <EmptyExecutionState statusCode={statusCode} />}

            {parsedPhonesList}

            {Array.isArray(phones) && phones.length > 3 && (
              <Row className="mrg-top-5 reset-mrg">
                <Button type="link" className="no-mrg no-pdd" onClick={onClickHandler}>
                  <i
                    className={`mrg-right-5
                    caf-ic_${isPhonesPanelOpened ? 'close' : 'plus'}`}
                  />
                  {translate(`${I18N_BASE_PATH}.viewMorePhones`)} ({phones?.length - 3})
                </Button>
              </Row>
            )}
          </Col>
        )}

        {!pfPhones && phones !== 'no-data' && phones !== 'query-failed' && (
          <Col span={24} className="contact-data-col">
            -
          </Col>
        )}
      </Row>
    </DataSourceCardCollapsePanel>
  );
};
