import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyExecutionState from 'components/EmptyExecutionState';

import { Row, Col } from 'antd';
import cards from 'assets/images/cards.svg';
import DataSourceCardCollapsePanel from '../../../DataSourceCardCollapsePanel';
import PeopleComplianceBetCard from './components/SportsAndBettingCard';

const I18N_BASE_PATH =
  'components.dataSourceCards.relationships.components.peopleComplianceBet';

const PeopleComplianceBet = ({ source = {}, toPrint = false /* urlId */ }) => {
  const { t: translate } = useTranslation();
  const QuerySucceeded = useMemo(
    () => source?.statusCode === '01' || source?.data?.statusCode === '01',
    [source]
  );

  return (
    <>
      <DataSourceCardCollapsePanel
        icon={null}
        customIcon={<img src={cards} alt="" style={{ margin: '0 14px 0 0' }} />}
        title={translate(`${I18N_BASE_PATH}.title`)}
        customId="restrictiveLists"
        toPrint={toPrint}
      >
        <Row id="internal-restrictive-lists" className="contact-data-container">
          {!QuerySucceeded && (
            <EmptyExecutionState
              statusCode={source?.data?.statusCode || source?.statusCode}
              message={source?.data?.message || source?.message}
            />
          )}
          {QuerySucceeded && !source.data?.results?.length && (
            <EmptyExecutionState
              statusCode={source?.data?.statusCode || source?.statusCode}
              message={source?.data?.message || source?.message}
            />
          )}
          {QuerySucceeded && source.data?.results?.length && (
            <Col className="reset-mrg" span={24}>
              <PeopleComplianceBetCard source={source} />
            </Col>
          )}
        </Row>
      </DataSourceCardCollapsePanel>
    </>
  );
};

export default PeopleComplianceBet;
