import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useExecution } from 'hooks/execution';
import { usePerson } from 'hooks/person';
import { useCompany } from 'hooks/company';

import templateServicesCategories from 'utils/data/templateServicesCategories.json';
import * as executionDataSources from 'utils/executionDataSource';

import { PfEmails } from './components/pfEmails';
import { PfPhones } from './components/pfPhones';
import { PfRelatedPeoplePhones } from './components/pfRelatedPeoplePhones';
import { ZipCodeDetails } from './components/zipCodeDetails';
import { EmailAgePrivate } from './components/emailAgePrivate';

import DataSourceCardCollapsePanel from '../DataSourceCardCollapsePanel';

import './styles.less';

const ContactInfos = ({ toPrint = false }) => {
  const { execution } = useExecution();
  const { company } = useCompany();
  const { person } = usePerson();
  const { t: translate } = useTranslation();

  const findSourceSection = useCallback(
    (section) =>
      executionDataSources.findSourceSection(execution, section) ||
      executionDataSources.findSourceSection(company, section) ||
      executionDataSources.findSourceSection(person, section),
    [company, execution, person]
  );

  const pfEmails = useMemo(() => findSourceSection('pfEmails'), [findSourceSection]);
  const pfPhones = useMemo(() => findSourceSection('pfPhones'), [findSourceSection]);
  const pfPhonesAlt = useMemo(() => findSourceSection('pfPhonesAlt'), [
    findSourceSection,
  ]);
  const pfRelatedPeoplePhones = useMemo(
    () => findSourceSection('pfRelatedPeoplePhones'),
    [findSourceSection]
  );
  const zipCodeDetails = useMemo(() => findSourceSection('zipCodeDetails'), [
    findSourceSection,
  ]);

  const emailAgePrivate = useMemo(() => findSourceSection('emailAgePrivate'), [
    findSourceSection,
  ]);

  return (
    <DataSourceCardCollapsePanel
      id="contact-infos-component"
      title={translate(templateServicesCategories.contact.title)?.toUpperCase()}
      icon={templateServicesCategories.contact.icon}
      toPrint={toPrint}
    >
      {pfEmails && <PfEmails source={pfEmails} toPrint={toPrint} />}
      {pfPhones && <PfPhones source={pfPhones} toPrint={toPrint} />}
      {pfPhonesAlt && <PfPhones source={pfPhonesAlt} toPrint={toPrint} type="alt" />}
      {pfRelatedPeoplePhones && (
        <PfRelatedPeoplePhones source={pfRelatedPeoplePhones} toPrint={toPrint} />
      )}
      {zipCodeDetails && (
        <ZipCodeDetails
          source={zipCodeDetails}
          sourceValidation={execution?.validations?.find(
            (v) => v.code === 'invalid_zip_code'
          )}
          cepParameter={execution?.parameters?.cep}
          toPrint={toPrint}
        />
      )}
      {emailAgePrivate && (
        <EmailAgePrivate
          source={emailAgePrivate}
          sourceValidation={execution?.validations?.find(
            (v) => v.code === 'invalid_zip_code'
          )}
          cepParameter={execution?.parameters?.cep}
          toPrint={toPrint}
        />
      )}
    </DataSourceCardCollapsePanel>
  );
};

export default ContactInfos;
