export const translateLevel = (level) => {
  if (!level) return 'Indisponível';

  switch (level.toUpperCase()) {
    case 'DIRECT':
      return 'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateLevel.DIRECT';
    case 'INDIRECT':
      return 'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateLevel.INDIRECT';
    default:
      return 'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateLevel.default';
  }
};

export const translateContactAndRelation = (type) => {
  if (!type)
    return {
      title:
        'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.hasNotType',
      icon: 'caf-ic_contact_',
    };

  switch (type?.toUpperCase()) {
    case 'PHONE':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.PHONE',
        icon: 'caf-ic_contact_',
      };
    case 'WORK':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.WORK',
        icon: 'caf-ic_company',
      };
    case 'MOBILE':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.MOBILE',
        icon: 'caf-ic_smartphone',
      };
    case 'OWNER':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.OWNER',
        icon: 'caf-ic_company',
      };
    case 'OWNERSHIP':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.OWNERSHIP',
        icon: 'caf-ic_company',
      };
    case 'CORPORATE':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.CORPORATE',
        icon: 'caf-ic_company',
      };
    case 'PERSONAL':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.PERSONAL',
        icon: 'caf-ic_person',
      };
    case 'HOME':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.HOME',
        icon: 'caf-ic_home',
      };
    case 'COWORKER':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.COWORKER',
        icon: 'caf-ic_git-branch',
      };
    case 'COLLEGECLASS':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.COLLEGECLASS',
        icon: 'caf-ic_git-branch',
      };
    case 'PARTNER':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.PARTNER',
        icon: 'caf-ic_users',
      };
    case 'EMPLOYMENT':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.EMPLOYMENT',
        icon: 'caf-ic_company',
      };
    case 'NEIGHBOR':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.NEIGHBOR',
        icon: 'caf-ic_users',
      };
    case 'BROTHER':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.BROTHER',
        icon: 'caf-ic_users',
      };
    case 'NEPHEW':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.NEPHEW',
        icon: 'caf-ic_users',
      };
    case 'MOTHER':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.MOTHER',
        icon: 'caf-ic_users',
      };
    case 'FATHER':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.FATHER',
        icon: 'caf-ic_users',
      };
    case 'GRANDSON':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.GRANDSON',
        icon: 'caf-ic_users',
      };
    case 'SON':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.SON',
        icon: 'caf-ic_users',
      };
    case 'HOUSEHOLD':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.HOUSEHOLD',
        icon: 'caf-ic_home',
      };
    case 'SPOUSE':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.SPOUSE',
        icon: 'caf-ic_users',
      };
    case 'RELATIVE':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.RELATIVE',
        icon: 'caf-ic_contact_',
      };
    case 'GRANDPARENT':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.GRANDPARENT',
        icon: 'caf-ic_users',
      };
    case 'UNCLE':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.UNCLE',
        icon: 'caf-ic_users',
      };
    case 'COUSIN':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.COUSIN',
        icon: 'caf-ic_users',
      };
    case 'RELATED':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.RELATED',
        icon: 'caf-ic_contact_',
      };
    case 'SITE':
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.SITE',
        icon: 'caf-ic_news',
      };
    default:
      return {
        title:
          'pages.private.profiles.components.contactAndRelationCard.utils.translate.translateContactAndRelation.default',
        icon: 'caf-ic_contact_',
      };
  }
};
