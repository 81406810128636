const documentVerificationCategory = [
  'type',
  'documentType',
  'documentCategory',
  'rg',
  'documentNumber',
  'number',
  'crvNumber',
  'claSecurityNumber',
  'registrationNumber',
  'crlvNumber',
  'renachNumber',
  'issuingCountry',
  'issueState',
  'issueStateCode',
  'cnhIssueState',
  'rgNumberAuthorityState',
  'mrz',
  'issueDate',
  'expirationDate',
  'authority',
  'issueAuthority',
  'rgIssuingAuthority',
  'documentTypeRgFront',
  'category',
  'firstDriverLicenseDate',
  'mirrorNumber',
  'notes',
  'placeOfIssue',
  'securityNumber',
  'filenameFront',
  'filenameBack',
  'via',
  'renavamCode',
  'rntrc',
  'assignmentYear',
  'pisPasep',
  'rgIssueState',
  'federativeUnit',
  'issuingAuthorityHeader',
  'headerRgFront',
  'documentSeries',
  'referenceDocument',
  'issuePlace',
  'institute',
  'department',
  'holderSignature',
  'footerRgBack',
  'lawRgBack',
  'rne',
  'validity',
  'entryDate',
  'rnm',
  'documentClassCode',
];
const personalDataCategory = [
  'name',
  'socialName',
  'cpf',
  'cnpj',
  'birthDate',
  'nationality',
  'nationalityCode',
  'naturality',
  'nativeCountry',
  'birthPlace',
  'gender',
  'sex',
  'fatherName',
  'motherName',
];
const miscellaneousInformationCategory = [
  'address',
  'graphicName',
  'acc',
  'plate',
  'previousPlateAndState',
  'chassis',
  'fuel',
  'brandAndModel',
  'manufactureYear',
  'modelYear',
  'capacityPowerCylinders',
  'predominantColor',
  'maritalStatus',
  'capacityInWeight',
  'weight',
  'motor',
  'cmt',
  'axles',
  'capacityInPeople',
  'body',
  'vehicleObservations',
  'classification',
  'legalProtection',
  'protocol',
  'residenceTerm',
];
const verifyFieldsOcrScreen = (ocrData) => {
  const documentVerificationFields = [];
  const personalDataFields = [];
  const miscellaneousInformationFields = [];

  const keys = Object.keys(ocrData);

  documentVerificationCategory.map((field) => {
    if (keys.includes(field) || ['issueDate'].includes(field)) {
      documentVerificationFields.push(field);
    }
  });
  personalDataCategory.map((field) => {
    if (
      keys.includes(field) ||
      ['name', 'socialName', 'motherName', 'fatherName', 'birthDate'].includes(field)
    ) {
      personalDataFields.push(field);
    }
  });
  miscellaneousInformationCategory.map((field) => {
    if (keys.includes(field)) {
      miscellaneousInformationFields.push(field);
    }
  });

  return {
    documentVerification: documentVerificationFields,
    personalData: personalDataFields,
    miscellaneousInformation: miscellaneousInformationFields,
  };
};

export { verifyFieldsOcrScreen };
