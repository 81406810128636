/* eslint-disable react/prop-types */

import React from 'react';
import { useI18nFormatters } from 'hooks/i18nFormatters';
import { useTranslation } from 'react-i18next';
import ball from 'assets/images/ball.svg';
import ColumnInformation from '../ColumnInformation';
import { formatCPF } from '../../utils';

const I18N_BASE_PATH =
  'components.dataSourceCards.relationships.components.peopleComplianceBet.index.components.SportsRelated';

export const SportsRelated = ({ sport, index }) => {
  const { i18nFormatDate } = useI18nFormatters();
  const { t: translate } = useTranslation();

  return (
    <>
      <div className="related-sports-title">
        <img src={ball} style={{ margin: '0px 8px 0px 0px' }} alt="" />
        <span>{translate(`${I18N_BASE_PATH}.title.sports`)}</span>
      </div>
      <div>
        <ColumnInformation
          title={translate(`${I18N_BASE_PATH}.column.title.sport`, { value: index + 1 })}
          fields={[
            {
              label: translate(`${I18N_BASE_PATH}.column.label.name`),
              value: translate(
                `${I18N_BASE_PATH}.column.value.${sport.sportName}`,
                sport.sportName
              ),
              span: 4,
            },
            {
              label: translate(`${I18N_BASE_PATH}.column.label.region`),
              value: sport.region,
              span: 4,
            },
            {
              label: translate(`${I18N_BASE_PATH}.column.label.total`),
              value: sport.totalRelatedEntities,
              span: 16,
            },
          ]}
        />
        {sport.relatedEntities.map((entity, index) => (
          <ColumnInformation
            noDivider={index === sport.relatedEntities.length - 1}
            title={translate(`${I18N_BASE_PATH}.column.title.entities`, {
              value: index + 1,
            })}
            fields={[
              {
                label: translate(`${I18N_BASE_PATH}.column.label.documentNumber`),
                value: formatCPF(entity.docNumber),
                span: 5,
                noRenderIfNotExists: true,
              },
              {
                label: translate(`${I18N_BASE_PATH}.column.label.documentType`),
                value: entity.docType,
                span: 19,
                noRenderIfNotExists: true,
              },
              {
                label: translate(`${I18N_BASE_PATH}.column.label.institutionName`),
                value: entity.institutionName,
                span: 5,
              },
              {
                label: translate(`${I18N_BASE_PATH}.column.label.institutionActivity`),
                value: entity.institutionActivity,
                span: 8,
              },
              {
                label: translate(`${I18N_BASE_PATH}.column.label.relationshipLevel`),
                value: entity.relationshipLevel
                  ? translate(
                      `components.dataSourceCards.relationships.components.peopleComplianceBet.index.relationshipLevel.value.${entity.relationshipLevel}`,
                      entity.relationshipLevel
                    )
                  : null,
                span: 11,
              },
              {
                label: translate(`${I18N_BASE_PATH}.column.label.role`),
                value: entity.role
                  ? translate(
                      `${I18N_BASE_PATH}.column.value.${entity.role}`,
                      entity.role
                    )
                  : null,
                span: 24,
              },
              {
                label: translate(`${I18N_BASE_PATH}.column.label.isActive`),
                value: entity.isActive,
                span: 4,
              },
              {
                label: translate(`${I18N_BASE_PATH}.column.label.startDate`),
                value: entity.startDate ? i18nFormatDate(entity.startDate, 1) : null,
                span: 4,
              },
              {
                label: translate(`${I18N_BASE_PATH}.column.label.endDate`),
                value: entity.endDate ? i18nFormatDate(entity.endDate, 1) : null,
                span: 4,
              },
              {
                label: translate(`${I18N_BASE_PATH}.column.label.source`),
                value: entity.source,
                span: 12,
              },
            ]}
          />
        ))}
      </div>
    </>
  );
};

export default SportsRelated;
