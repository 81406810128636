/* eslint-disable */

import React from 'react';
import PropTypes, { bool } from 'prop-types';
import { Row, Divider, Col } from 'antd';
import NotAvailable from 'components/NotAvailable';
import { useTranslation } from 'react-i18next';
import '../../styles.less';

const I18N_BASE_PATH =
  'components.dataSourceCards.relationships.components.peopleComplianceBet.index.components.ColumnInformation';

const ColumnInformation = ({ title = null, fields, noDivider = false }) => {
  const { t: translation } = useTranslation();

  return (
    <div className="column-information">
      {title && <Row className="reset-mrg column-information-title">{title}</Row>}
      <Row className="column-information-fields">
        {fields.map((field, index) => (
          <React.Fragment key={index}>
            {field.noRenderIfNotExists && !field.value ? null : (
              <Col className="ant-col fields-col" span={field.span || 6}>
                <strong className="column-information-label">{field.label}</strong>
                <div className="column-information-value">
                  {typeof field.value === 'boolean'
                    ? field.value
                      ? translation(`${I18N_BASE_PATH}.value.yes`)
                      : translation(`${I18N_BASE_PATH}.value.no`)
                    : field.value || <NotAvailable />}
                </div>
              </Col>
            )}
          </React.Fragment>
        ))}
      </Row>
      {!noDivider && <Divider style={{ color: '#D1D1D1' }} />}
    </div>
  );
};

ColumnInformation.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      span: PropTypes.number,
      noRenderIfNotExists: PropTypes.bool,
    })
  ).isRequired,
  noDivider: bool,
};

export default ColumnInformation;
