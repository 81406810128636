import React, { useState, useCallback, useMemo } from 'react';
import { Modal, Button, Row, Col } from 'antd';

import PageTitle from 'components/PageTitle';

import './styles.less';

const ConfirmAction = ({
  title,
  description,
  subtitle,
  refreshListRef,
  action,
  loading,
  children,
  okButton,
  cancelButton,
  danger = false,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [id, setId] = useState(null);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const openModal = useCallback((param) => {
    setId(param);
    setModalVisible(true);
  }, []);

  const ConfirmActionModal = useMemo(
    () => (
      <Modal
        visible={modalVisible}
        wrapClassName="caf-modal confirm-action"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        onCancel={closeModal}
        footer={null}
        width={550}
        destroyOnClose
      >
        <div className="no-mrg no-pdd">
          <Row>
            <Col span={24}>
              <div className="activate-confirm-title">
                <PageTitle title={title} />
              </div>
            </Col>
            <Col span={24} className="activate-confirm-description">
              {subtitle}
            </Col>
          </Row>
          {description && (
            <Row>
              <Col span={24} className="activate-confirm-description">
                {description}
              </Col>
            </Row>
          )}
          {children}
          <Row>
            <Col span={24}>
              <div className="activate-confirm-btns">
                <Button
                  type="default"
                  onClick={closeModal}
                  className="btn-cancel"
                  disabled={loading}
                >
                  {cancelButton}
                </Button>

                <Button
                  type={danger ? 'danger' : 'primary'}
                  htmlType="submit"
                  onClick={async () => {
                    await action(id);
                    closeModal();
                    if (refreshListRef) refreshListRef();
                  }}
                  loading={loading}
                >
                  {okButton}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    ),
    [
      modalVisible,
      closeModal,
      title,
      subtitle,
      children,
      loading,
      cancelButton,
      danger,
      okButton,
      action,
      id,
      refreshListRef,
    ]
  );

  return { openModal, closeModal, ConfirmActionModal };
};

export default ConfirmAction;
