export const formatCPF = (documentNumber) => {
  if (!/^\d{11}$/.test(documentNumber)) {
    return documentNumber;
  }

  return documentNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

export const formatCNPJ = (documentNumber) => {
  if (!/^\d{14}$/.test(documentNumber)) {
    return documentNumber;
  }

  return documentNumber.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};
