/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import { toMaskedCpf } from 'utils/formatters';

import TableInfoCard from 'pages/private/Profiles/components/TableInfoCard/Column';

const ValidationsRGData = ({ data }) => {
  const { t: translate } = useTranslation();

  return (
    <>
      <Row gutter={[32, 24]} className="custom-validations-data">
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsRGData.index.validationsRGData.tableInfoCard.title.name'
          )}
          content={data?.name}
        />
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsRGData.index.validationsRGData.tableInfoCard.title.socialName'
          )}
          content={data?.socialName}
        />
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsRGData.index.validationsRGData.tableInfoCard.title.cpf'
          )}
          content={toMaskedCpf(data?.cpf)}
        />
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsRGData.index.validationsRGData.tableInfoCard.title.rg'
          )}
          content={data?.rg}
        />
      </Row>

      <Row gutter={[32, 24]} className="custom-validations-data">
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsRGData.index.validationsRGData.tableInfoCard.title.birthDate'
          )}
          content={data?.birthDate}
        />
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsRGData.index.validationsRGData.tableInfoCard.title.motherName'
          )}
          content={data?.motherName}
        />
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsRGData.index.validationsRGData.tableInfoCard.title.fatherName'
          )}
          content={data?.fatherName}
        />
      </Row>

      <Row gutter={[32, 24]} className="custom-validations-data">
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsRGData.index.validationsRGData.tableInfoCard.title.birthPlace'
          )}
          content={data?.birthPlace}
        />
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsRGData.index.validationsRGData.tableInfoCard.title.referenceDocument'
          )}
          content={data?.referenceDocument}
        />
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsRGData.index.validationsRGData.tableInfoCard.title.rgIssuingAuthority'
          )}
          content={data?.rgIssuingAuthority}
        />
      </Row>

      <Row gutter={[32, 24]} className="custom-validations-data">
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsRGData.index.validationsRGData.tableInfoCard.title.rgIssueState'
          )}
          content={data?.rgIssueState}
          bordered={false}
        />
        <TableInfoCard
          span={8}
          title={translate(
            'pages.private.profiles.components.tabs.components.documentsValidations.components.shared.validationsRGData.index.validationsRGData.tableInfoCard.title.rgIssueDate'
          )}
          content={data?.rgIssueDate}
          bordered={false}
        />
      </Row>
    </>
  );
};

export default ValidationsRGData;
