import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, InputNumber, Progress } from 'antd';
import { useTranslation } from 'react-i18next';

const parameterTranslate = {
  CPF: 'Cpf',
  Nome: 'Name',
  'Data de Nascimento': 'BirthDate',
};

const DynamicThresholdForm = ({
  selectedParameters,
  initialThresholdValues,
  disabled,
  field,
  defaultValue = 80,
}) => {
  const { t: translate } = useTranslation();
  const [thresholdValues, setThresholdValues] = useState(initialThresholdValues);

  const handleThresholdChange = (value, key) => {
    setThresholdValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const getColor = (value, thresholds) => {
    const threshold = thresholds.find((threshold) => value >= threshold.min);
    return threshold ? threshold.color : '#f8b239';
  };

  const getDescription = (value, thresholds, parameter, defaultValue, thresholdMap) => {
    const threshold = thresholds.find((threshold) => value >= threshold.min);
    if (!threshold) return '';

    const baseDescription = translate(
      `components.dynamicThresholdForm.description.${threshold.descriptionKey}`,
      threshold.description
    );

    const recommendedValue = thresholdMap?.[parameter] || defaultValue;
    if (value === recommendedValue && threshold.recommended) {
      return `${baseDescription} ${translate(
        'components.dynamicThresholdForm.description.recommended',
        threshold.recommended
      )}`;
    }

    return baseDescription;
  };

  return (
    <Row className="mrg-top-15 mrg-btm-15 mrg-right-15 no-pdd">
      {selectedParameters.map((parameter) => {
        const translatedParameter = parameterTranslate[parameter] || parameter;
        const thresholdKey = `threshold${translatedParameter.replace(/\s+/g, '')}`;

        const thresholdMap = {
          Source01: 50,
          Source02: 70,
        };

        const value =
          thresholdValues?.[thresholdKey] ?? thresholdMap?.[parameter] ?? defaultValue;

        const span = Math.max(24 / selectedParameters.length, 8);

        const variableValue = 10;

        const veryHighValue = (thresholdMap?.[parameter] ?? defaultValue) + variableValue;
        const defaultValueMin = thresholdMap?.[parameter] ?? defaultValue;
        const moderateValue = (thresholdMap?.[parameter] ?? defaultValue) - variableValue;

        const thresholds = [
          {
            min: veryHighValue,
            color: '#106b31',
            descriptionKey: 'veryHigh',
            description:
              'Porcentagem muito alta, podendo ser restritiva e rejeitar comparações relevantes.',
          },
          {
            min: defaultValueMin,
            color: '#39c560',
            descriptionKey: 'high',
            description:
              'Alta porcentagem, ideal para análises de similaridade confiáveis.',
            recommended: '(Recomendado Caf)',
          },
          {
            min: moderateValue,
            color: '#f8b239',
            descriptionKey: 'moderate',
            description:
              'Porcentagem moderada, adequada para comparações menos rigorosas.',
          },
          {
            min: 0,
            color: '#e21b45',
            descriptionKey: 'low',
            description:
              'Baixa porcentagem de similaridade, indicando alto risco de inconsistência.',
          },
        ];

        return (
          <Col span={span} key={parameter}>
            <Form.Item
              name={[field.name, thresholdKey]}
              fieldKey={[field.fieldKey, thresholdKey]}
              label={
                <span>
                  {translate(
                    'components.dynamicThresholdForm.form.label',
                    'Defina a porcentagem mínima para'
                  )}{' '}
                  <span className="text-bold">
                    {translate(
                      `components.dynamicThresholdForm.parameter.${translatedParameter}`,
                      parameter
                    )}
                  </span>
                  :
                </span>
              }
              rules={[
                {
                  required: true,
                  message: translate(
                    'components.dynamicThresholdForm.form.required.message',
                    'Por favor, insira um valor'
                  ),
                },
                {
                  validator: (_, value) =>
                    value >= 20 && value <= 100
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            translate(
                              'components.dynamicThresholdForm.form.rule.message',
                              'O valor deve estar entre 20% e 100%'
                            )
                          )
                        ),
                },
              ]}
              initialValue={value || defaultValue}
              tooltip={
                thresholdMap[parameter]
                  ? translate(
                      `components.dynamicThresholdForm.parameter.${parameter}.tooltip`
                    )
                  : translate(
                      'components.dynamicThresholdForm.form.tooltip',
                      {
                        parameter: translate(
                          `components.dynamicThresholdForm.parameter.${translatedParameter}`,
                          parameter
                        ),
                        defaultValue: thresholdMap?.[parameter] || defaultValue,
                      },
                      `Esse valor define o limite mínimo para a porcentagem de similaridade aceita para o campo ${parameter}. O recomendado é ${
                        thresholdMap?.[parameter] || defaultValue
                      }%.`
                    )
              }
            >
              <InputNumber
                disabled={disabled}
                max={100}
                min={20}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace('%', '')}
                onChange={(value) => handleThresholdChange(value, thresholdKey)}
                value={value}
              />
            </Form.Item>
            <div>
              <Progress
                percent={value}
                status="active"
                className="mrg-top-10"
                strokeColor={getColor(value, thresholds)}
              />
              <span className="text-bold">
                {getDescription(value, thresholds, parameter, defaultValue, thresholdMap)}
              </span>
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

DynamicThresholdForm.propTypes = {
  selectedParameters: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialThresholdValues: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  field: PropTypes.object.isRequired,
  defaultValue: PropTypes.number,
};

DynamicThresholdForm.defaultProps = {
  disabled: false,
  defaultValue: 80,
};

export default DynamicThresholdForm;
