import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Row, Col, Switch, message, Tooltip, Tag as AntTag } from 'antd';

import { useI18nFormatters } from 'hooks/i18nFormatters';
import { useReports } from 'hooks/reports';
import { useProfileTemplate } from 'hooks/profileTemplate';
import { hasFeatureFlag } from 'utils/verifyFeatureFlag';
import { useAuth } from 'hooks/auth';
import Tag from 'components/Tag';
import TooltipInfo from 'components/TooltipInfo';
import { toMaskedCpf, toMaskedCnpj } from 'utils/formatters';

import PermissionWrapper from 'routes/PermissionWrapper';

import useConfirmAction from 'pages/private/ProfileModels/ProfileModels/components/ConfirmAction';
import statusTag from './utils/statusTag';
import notificationStatusTag from './utils/notificationStatusTag';

import './styles.less';

const CardLink = ({
  data: onboardingLink,
  refreshList,
  allProfileTemplates,
  workflowsData,
}) => {
  const { t: translate } = useTranslation();
  const { i18nFormatDate } = useI18nFormatters();
  const { user } = useAuth();
  const { allReportsObject } = useReports();
  const { openModal: openConfirmActionModal, ConfirmActionModal } = useConfirmAction({
    refreshListRef: refreshList,
    type: 'onboarding-link',
  });
  const { _id, active } = onboardingLink;

  const onboardingLinkHasPersonId = useMemo(() => {
    const personId = onboardingLink?.attributes?.personId;

    if (personId && personId?.length >= 0) return personId;

    return false;
  }, [onboardingLink]);

  const onboardingLinkHasCompanyId = useMemo(() => {
    const companyId = onboardingLink?.attributes?.companyId;

    if (companyId && companyId?.length >= 0) return companyId;

    return false;
  }, [onboardingLink]);

  const onboardingLinkHasCPF = useMemo(() => {
    const cpf = onboardingLink?.attributes?.cpf;

    if (cpf && cpf?.length > 10) return cpf;

    return false;
  }, [onboardingLink]);

  const onboardingLinkHasCNPJ = useMemo(() => {
    const cnpj = onboardingLink?.attributes?.cnpj;

    if (cnpj && cnpj?.length > 13) return cnpj;

    return false;
  }, [onboardingLink]);

  const onboardingLinkStatus = useMemo(() => {
    if (onboardingLink?.oneTimeLinkAcessed && onboardingLink?.status !== 'COMPLETED') {
      return 'oneTimeLinkAcessed';
    }

    if (onboardingLink?.noExpire) {
      return 'noExpire';
    }

    return onboardingLink?.status;
  }, [onboardingLink]);

  const onboardingLinkIcon = useMemo(() => {
    if (onboardingLinkHasCPF || onboardingLinkHasPersonId) {
      return 'caf-ic_person';
    }

    if (onboardingLinkHasCNPJ || onboardingLinkHasCompanyId) {
      return 'caf-ic_company';
    }

    return 'caf-ic_news';
  }, [
    onboardingLinkHasCPF,
    onboardingLinkHasCNPJ,
    onboardingLinkHasCompanyId,
    onboardingLinkHasPersonId,
  ]);

  const onboardingLinkTitle = useMemo(() => {
    const name = onboardingLink?.attributes?.name;

    if (onboardingLinkHasPersonId || onboardingLinkHasCompanyId) {
      if ((onboardingLinkHasPersonId || onboardingLinkHasCompanyId) && name) {
        return `${name} | ${onboardingLinkHasPersonId || onboardingLinkHasCompanyId}`;
      }

      return onboardingLinkHasPersonId || onboardingLinkHasCompanyId;
    }
    if (onboardingLinkHasCPF || onboardingLinkHasCNPJ) {
      if ((onboardingLinkHasCPF || onboardingLinkHasCNPJ) && name) {
        return `${name} | ${
          toMaskedCpf(onboardingLinkHasCPF) || toMaskedCnpj(onboardingLinkHasCNPJ)
        }`;
      }

      return toMaskedCpf(onboardingLinkHasCPF) || toMaskedCnpj(onboardingLinkHasCNPJ);
    }

    return translate(
      'pages.private.profileModels.profileModelList.components.links.components.cardLink.index.cardLink.title.default' // => Link compartilhado
    );
  }, [
    onboardingLink,
    onboardingLinkHasCNPJ,
    onboardingLinkHasCPF,
    onboardingLinkHasPersonId,
    onboardingLinkHasCompanyId,
    translate,
  ]);

  const onboardingLinkProfileTemplate = useMemo(() => {
    let template;
    let data = {};

    if (onboardingLink?.templateId && allProfileTemplates?.length > 0) {
      template = allProfileTemplates?.find(
        (t) => t._id === onboardingLink?.transactionTemplateId
      );

      data = {
        type: 'profileTemplate',
        name: template?.name,
        isActive: template?.active,
      };

      if (template?.name) return data;
    }

    template = allReportsObject[onboardingLink?.reportId];

    data = {
      type: template?.name ? 'report' : 'default',
      name: template?.name,
    };

    return data || {};
  }, [allProfileTemplates, allReportsObject, onboardingLink]);

  const onboardingLinkWorkflow = useMemo(() => {
    let workflowTemplate;
    let data = {};

    if (workflowsData?.docs?.length > 0) {
      workflowTemplate = workflowsData?.docs?.find(
        (t) => t._id === onboardingLink?.workflowId
      );
      data = {
        type: 'workflow',
        name: workflowTemplate?.definition?.name,
        isActive: workflowTemplate?.active,
      };

      if (workflowTemplate?.definition?.name) return data;
    }

    data = {
      type: workflowTemplate?.definition?.name ? 'report' : 'default',
      name: workflowTemplate?.definition?.name,
    };

    return data || {};
  }, [workflowsData, onboardingLink]);

  const tooltipProfileTemplateInactive = useMemo(() => {
    const defaultTitle = translate(
      'pages.private.profileModels.profileModelList.components.links.components.cardLink.index.cardLink.tooltipProfileTemplateInactive.defaultTitle' // => Este link está inativo pois o modelo de consulta atrelado a ele está inativado. Caso queria ativar esse link, primeiro ative o modelo de consulta
    );

    let customTitle;

    if (onboardingLinkProfileTemplate?.name) {
      customTitle = (
        <>
          {defaultTitle}{' '}
          {translate(
            'pages.private.profileModels.profileModelList.components.links.components.cardLink.index.cardLink.tooltipProfileTemplateInactive.customTitle' // => vinculado
          )}
          :{' '}
          <span className="gx-font-italic text-bold gx-font-weight-light font-size-12">
            {onboardingLinkProfileTemplate?.name}.
          </span>
        </>
      );

      return customTitle;
    }

    customTitle = `${defaultTitle}.`;

    return customTitle;
  }, [onboardingLinkProfileTemplate, translate]);

  const getTemplate = allProfileTemplates?.find(
    (t) => t._id === onboardingLink?.transactionTemplateId
  );

  const templateSourceNameTitle = useMemo(() => {
    if (onboardingLinkWorkflow?.name) {
      return translate(
        'pages.private.profileModels.profileModelList.components.links.components.cardLink.index.cardLink.info.workflow.title' // => Workflow
      );
    }

    if (onboardingLinkProfileTemplate?.type === 'report') {
      return translate(
        'pages.private.profileModels.profileModelList.components.links.components.cardLink.index.cardLink.info.profileTemplates.title.reportName' // => Relatório
      );
    }

    return translate(
      'pages.private.profileModels.profileModelList.components.links.components.cardLink.index.cardLink.info.profileTemplates.title.profileTemplate' // => Modelo de consulta
    );
  }, [onboardingLinkProfileTemplate, onboardingLinkWorkflow, translate]);

  const templateSourceName = useMemo(() => {
    if (onboardingLinkWorkflow?.name) {
      return onboardingLinkWorkflow?.name;
    }

    if (onboardingLinkProfileTemplate?.name) {
      return onboardingLinkProfileTemplate?.name;
    }

    return '-';
  }, [onboardingLinkProfileTemplate, onboardingLinkWorkflow]);

  return (
    <>
      {ConfirmActionModal}
      <div className="mrg-top-15">
        <section id="card-link-wrapper">
          <Row>
            <Col span={8}>
              <div className="flex pdd-top-15 pdd-btm-5 pdd-left-20">
                <div className="flex center link-type-icon">
                  <i className={`${onboardingLinkIcon} font-size-22 text-bold`} />
                </div>
                <section className="mrg-left-20 pdd-vertical-5 data-link-info">
                  <h3 className="font-size-14 text-bold">{onboardingLinkTitle}</h3>
                  <div>
                    <section className="onboarding-link-name">
                      <p className="font-size-14 text-dark max-lines-1">
                        <a
                          href={`${onboardingLink?.url}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {onboardingLink?.url}
                        </a>
                      </p>
                      <CopyToClipboard
                        text={onboardingLink?.url}
                        onCopy={() =>
                          message.success(
                            translate(
                              'pages.private.profileModels.profileModelList.components.links.components.cardLink.index.cardLink.copyToClipboard.onCopy.message' // => Link de onboarding copiado para a área de transferência
                            )
                          )
                        }
                      >
                        <small className="mrg-left-10 font-size-14 text-bold">
                          {translate(
                            'pages.private.profileModels.profileModelList.components.links.components.cardLink.index.cardLink.copyToClipboard.text' // => Copiar
                          )}
                        </small>
                      </CopyToClipboard>
                    </section>
                  </div>
                </section>
              </div>
            </Col>
            <Col span={5}>
              <div className="flex flex-column center pdd-top-15 pdd-btm-5">
                <h3 style={{ textAlign: 'center' }} className="text-bold font-size-14">
                  {templateSourceNameTitle}
                </h3>
                <p style={{ textAlign: 'center' }}>{templateSourceName}</p>
              </div>
            </Col>
            <Col
              span={4}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="flex flex-column center pdd-top-15 pdd-btm-15">
                <h3 className="text-bold font-size-14">
                  {translate(
                    'pages.private.profileModels.profileModelList.components.links.components.cardLink.index.cardLink.info.contactMethod.title' // => Contact Method
                  )}
                </h3>
                {(onboardingLink?.email || onboardingLink?.smsPhoneNumber) && (
                  <>
                    {onboardingLink?.smsPhoneNumber && (
                      <div className="flex flex-column align-start pdd-top-5">
                        <div className="flex">
                          <div className="flex align-center">
                            <Tooltip title={`SMS: +${onboardingLink?.smsPhoneNumber}`}>
                              <i className="caf-ic_smartphone font-size-20 text-dark" />
                            </Tooltip>
                          </div>
                          <div className="flex" style={{ marginLeft: '10px' }}>
                            <div className="flex align-center">
                              <Tooltip
                                title={
                                  onboardingLink?.statusNotificationSms?.reason ?? ' '
                                }
                              >
                                <Tag
                                  color={
                                    notificationStatusTag[
                                      onboardingLink?.statusNotificationSms?.status ??
                                        'PENDING'
                                    ].color
                                  }
                                  className="flex align-center"
                                >
                                  <span className="font-size-12">
                                    {translate(
                                      notificationStatusTag[
                                        onboardingLink?.statusNotificationSms?.status ??
                                          'PENDING'
                                      ].title
                                    )}
                                  </span>
                                </Tag>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="flex">
                          {onboardingLink?.phoneValidation && (
                            <div
                              className="flex align-center"
                              style={{ marginTop: '5px' }}
                            >
                              <span className="font-size-12">
                                {translate(
                                  'pages.private.profileModels.profileModelList.components.links.components.cardLink.index.cardLink.info.code'
                                )}
                                : {onboardingLink?.phoneValidation}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {onboardingLink?.email && (
                      <div className="flex flex-column align-start pdd-top-5">
                        <div className="flex">
                          <div className="flex align-center">
                            <Tooltip title={`E-mail: ${onboardingLink?.email}`}>
                              <i className="caf-ic_mail font-size-20 text-dark" />
                            </Tooltip>
                          </div>
                          <div className="flex" style={{ marginLeft: '10px' }}>
                            <div className="flex align-center">
                              <Tooltip
                                title={
                                  onboardingLink?.statusNotificationEmail?.reason ?? ' '
                                }
                              >
                                <Tag
                                  color={
                                    notificationStatusTag[
                                      onboardingLink?.statusNotificationEmail?.status ??
                                        'PENDING'
                                    ].color
                                  }
                                  className="flex align-center"
                                >
                                  <span className="font-size-12">
                                    {translate(
                                      notificationStatusTag[
                                        onboardingLink?.statusNotificationEmail?.status ??
                                          'PENDING'
                                      ].title
                                    )}
                                  </span>
                                </Tag>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="flex">
                          {onboardingLink?.emailValidation && (
                            <div
                              className="flex align-center"
                              style={{ marginTop: '5px' }}
                            >
                              <span className="font-size-12">
                                {translate(
                                  'pages.private.profileModels.profileModelList.components.links.components.cardLink.index.cardLink.info.code'
                                )}
                                : {onboardingLink?.emailValidation}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
                {!onboardingLink?.email && !onboardingLink?.smsPhoneNumber && (
                  <Tag color={notificationStatusTag.NOT_INFORMED.color}>
                    <span className="font-size-12">
                      {translate(notificationStatusTag.NOT_INFORMED.title)}
                    </span>{' '}
                  </Tag>
                )}
              </div>
            </Col>
            <Col span={4}>
              <div className="flex flex-column center pdd-top-15 pdd-btm-5">
                <h3 className="text-bold font-size-14">
                  {translate(
                    'pages.private.profileModels.profileModelList.components.links.components.cardLink.index.cardLink.info.status.title' // => Status
                  )}
                </h3>
                {onboardingLinkStatus ? (
                  <Tag color={statusTag[onboardingLinkStatus].color}>
                    <span className="font-size-12">
                      {translate(statusTag[onboardingLinkStatus].title)}
                    </span>{' '}
                    <i className={`${statusTag[onboardingLinkStatus].icon}`} />
                  </Tag>
                ) : (
                  '-'
                )}
              </div>
            </Col>
            <Col span={2}>
              <PermissionWrapper
                requiredPermissions={['onboardings:update']}
                forbiddenContent={
                  <AntTag
                    id="active-inactive-tag"
                    className={
                      active ? 'tag-active mrg-left-15' : 'tag-inactive mrg-left-15'
                    }
                  >
                    {translate(
                      `pages.private.profileModels.profileModelList.components.links.components.cardLink.index.cardLink.info.active_inactive.title.${
                        active ? 'active' : 'inactive' // => Ativo : Inativo
                      }`
                    )}
                  </AntTag>
                }
              >
                {(!onboardingLinkProfileTemplate?.isActive &&
                  !active &&
                  getTemplate?.active) ||
                (!onboardingLinkWorkflow?.isActive && !active && !getTemplate?.active) ? (
                  <div className="flex center flex-column profile-template-inactive-icon">
                    <TooltipInfo
                      color="#2f80ed"
                      placement="top"
                      title={tooltipProfileTemplateInactive}
                      size={16}
                    />
                    <small className="text-center mrg-top-5 font-size-11 text-dark text-semibold">
                      {translate(
                        'pages.private.profileModels.profileModelList.components.links.components.cardLink.index.cardLink.info.active_inactive.title.profileTemplateInactive' // => Modelo de consulta inativo
                      )}
                    </small>
                  </div>
                ) : (
                  <div className="flex pdd-top-15 pdd-btm-5 flex-column center">
                    <Switch
                      size="small"
                      checked={!!active}
                      onChange={(checked) => openConfirmActionModal(checked, _id)}
                    />
                    <h3 className="font-size-14 text-bold mrg-top-10">
                      {translate(
                        `pages.private.profileModels.profileModelList.components.links.components.cardLink.index.cardLink.info.active_inactive.title.${
                          active ? 'active' : 'inactive' // => Ativo : Inativo
                        }`
                      )}
                    </h3>
                  </div>
                )}
              </PermissionWrapper>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <section
                id="link-date-info"
                className="flex pdd-left-20 mrg-bottom-15 pdd-top-10"
              >
                <p className="gx-font-italic gx-font-weight-light font-size-12">
                  {translate(
                    'pages.private.profileModels.profileModelList.components.links.components.cardLink.index.cardLink.info.createdAt.text' // => Criado em
                  )}{' '}
                  {i18nFormatDate(onboardingLink?.createdAt, 7)}{' '}
                  {onboardingLink?.createdBy?.name && (
                    <>
                      {translate(
                        'pages.private.profileModels.profileModelList.components.links.components.cardLink.index.cardLink.info.createdAt.user' // => por
                      )}{' '}
                      {onboardingLink?.createdBy?.name}
                    </>
                  )}
                </p>
              </section>
            </Col>
          </Row>
        </section>
      </div>
    </>
  );
};

CardLink.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.shape({
      id: PropTypes.string,
    }),
    active: PropTypes.bool,
    url: PropTypes.string,
    transactionTemplateId: PropTypes.string,
    noExpire: PropTypes.string,
    status: PropTypes.string,
    createdAt: PropTypes.string,
    createdBy: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  refreshList: PropTypes.func.isRequired,
  allProfileTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      active: PropTypes.bool,
    })
  ).isRequired,
  workflowsData: PropTypes.shape({
    docs: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        definition: PropTypes.shape({
          name: PropTypes.string,
        }),
      })
    ),
  }).isRequired,
};

export default CardLink;
