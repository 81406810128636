/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Spin } from 'antd';

import { isEmpty } from 'utils/isObjectEmpty';
import { toFormatPercentage } from 'utils/formatters';

import DossierCard from 'pages/private/Profiles/components/DossierCard';
import TableInfoCard from 'pages/private/Profiles/components/TableInfoCard/Column';

import ZoomedValidationsImagesCard from 'components/ZoomedValidationsImagesCard';
import { useExecution } from 'hooks/execution';
import { useAuth } from 'hooks/auth';
import ValidationsOfficialData from '../../../DocumentsValidations/components/Shared/ValidationsOfficialData';
import ValidationsPfBiometriaSerasa from '../../../DocumentsValidations/components/Shared/ValidationsPfBiometriaSerasa';

import { genderTranslate } from '../../utils/translations';

import EmptyDocuments from '../Shared/EmptyDocuments';

const VerifiedIdentityCard = ({ loading, status }) => {
  const { t: translate } = useTranslation();
  const { user } = useAuth();
  const { execution } = useExecution();

  const { docImage, selfie } = useMemo(() => {
    const usedDocTypes = execution?.sections?.facematch?.query?.data?.usedDocImage
      ? [execution?.sections?.facematch?.query?.data?.usedDocImage?.toUpperCase()]
      : ['FRONT', 'PASSPORT_FRONT', 'VISA_FRONT'];

    return {
      docImage: execution?.files?.find((file) => usedDocTypes.includes(file.type))
        ?.signUrl,
      selfie: execution?.files?.find((file) => file.type === 'SELFIE')?.signUrl,
    };
  }, [execution]);

  const executionData = useMemo(() => {
    if (user?.accountData?.featureFlags?.isWorkflowBuilder) {
      return execution?.sections?.facematch?.data;
    }
    return execution?.sections?.facematch;
  }, [execution, user]);
  return (
    <DossierCard
      title={translate(
        'pages.private.executions.components.tabs.components.verifiedIdentity.components.verifiedIdentity.index.dossierCard.title'
      )}
      subtitle={translate(
        'pages.private.executions.components.tabs.components.verifiedIdentity.components.verifiedIdentity.index.dossierCard.subtitle'
      )}
      icon="caf-ic_user_active"
      customId="verified-document-card"
    >
      {loading && (
        <div className="custom-document-loading">
          <Spin />
        </div>
      )}

      <>
        {!loading && (!execution || isEmpty(execution)) ? (
          <EmptyDocuments type="verified-identity" />
        ) : (
          <>
            <ZoomedValidationsImagesCard
              firstImageDescription={translate(
                'pages.private.executions.components.tabs.components.verifiedIdentity.components.verifiedIdentity.index.zoomedValidationsImagesCard.firstImageDescription'
              )}
              firstImage={
                user?.accountData?.featureFlags?.isWorkflowBuilder
                  ? docImage
                  : execution?.images?.front
              }
              secondImageDescription={translate(
                'pages.private.executions.components.tabs.components.verifiedIdentity.components.verifiedIdentity.index.zoomedValidationsImagesCard.secondImageDescription'
              )}
              secondImage={
                user?.accountData?.featureFlags?.isWorkflowBuilder
                  ? selfie
                  : execution?.images?.selfie
              }
              customTop={status ? '15px' : '0 !important'}
            />

            <Row className="custom-validations-title reset-mrg mrg-top-45 mrg-btm-10">
              <strong>
                {translate(
                  'pages.private.executions.components.tabs.components.verifiedIdentity.components.verifiedIdentity.index.facematch.title'
                )}
              </strong>
            </Row>
            {execution?.sections?.facematch ? (
              <>
                <Row gutter={[32, 16]} className="custom-validations-execution">
                  <TableInfoCard
                    span={6}
                    title={translate(
                      'pages.private.executions.components.tabs.components.verifiedIdentity.components.verifiedIdentity.index.facematch.tableInfoCard.confidence.title'
                    )}
                    content={
                      executionData?.originalIdentical === false &&
                      executionData?.identical &&
                      executionData?.verified
                        ? translate(
                            'pages.private.executions.components.tabs.components.verifiedIdentity.components.verifiedIdentity.index.facematch.tableInfoCard.confidence.manualReview'
                          )
                        : toFormatPercentage(executionData?.confidence, translate)
                    }
                    bordered={false}
                  />
                  <TableInfoCard
                    span={6}
                    title={translate(
                      'pages.private.executions.components.tabs.components.verifiedIdentity.components.verifiedIdentity.index.facematch.tableInfoCard.gender.title'
                    )}
                    content={translate(genderTranslate(executionData?.gender))}
                    bordered={false}
                  />
                  <TableInfoCard
                    span={6}
                    title={translate(
                      'pages.private.executions.components.tabs.components.verifiedIdentity.components.verifiedIdentity.index.facematch.tableInfoCard.ageRange.title'
                    )}
                    content={
                      executionData?.ageRange &&
                      `${executionData?.ageRange} ${translate(
                        'pages.private.executions.components.tabs.components.verifiedIdentity.components.verifiedIdentity.index.facematch.tableInfoCard.ageRange.content'
                      )}`
                    }
                    bordered={false}
                  />
                </Row>
              </>
            ) : (
              translate(
                'pages.private.executions.components.tabs.components.verifiedIdentity.components.verifiedIdentity.index.default'
              )
            )}
            {execution?.sections?.sharedFaceset && (
              <>
                <Row className="custom-validations-title reset-mrg mrg-top-45 mrg-btm-10">
                  <strong>
                    {translate(
                      'pages.private.executions.components.tabs.components.verifiedIdentity.components.verifiedIdentity.index.sharedFaceset.title'
                    )}
                  </strong>
                </Row>
                <Row gutter={[32, 16]} className="custom-validations-execution">
                  <TableInfoCard
                    span={24}
                    content={
                      execution?.validations?.some(
                        (validation) =>
                          validation.code === 'shared_faceset_not_contains_face' &&
                          validation.status === 'INVALID'
                      )
                        ? translate(
                            'pages.private.executions.components.tabs.components.verifiedIdentity.components.verifiedIdentity.index.sharedFaceset.facematch.true'
                          )
                        : translate(
                            'pages.private.executions.components.tabs.components.verifiedIdentity.components.verifiedIdentity.index.sharedFaceset.facematch.false'
                          )
                    }
                    bordered={false}
                  />
                </Row>
              </>
            )}
            {!user?.accountData?.featureFlags?.isWorkflowBuilder && (
              <>
                <Row className="custom-validations-title reset-mrg mrg-top-45 mrg-btm-10">
                  <strong>
                    {translate(
                      'pages.private.executions.components.tabs.components.documentsValidations.index.documentsValidations.rows.OFFICIAL_DATA'
                    )}
                  </strong>
                </Row>
                <ValidationsOfficialData data={execution} />
                {execution?.sections?.pfBiometriaSerasa && (
                  <>
                    <Row className="custom-validations-title reset-mrg mrg-top-45 mrg-btm-10">
                      <strong>
                        {translate(
                          'pages.private.executions.components.tabs.components.documentsValidations.index.documentsValidations.rows.SERASA_BIOMETRICS'
                        )}
                      </strong>
                    </Row>
                    <ValidationsPfBiometriaSerasa data={execution} />
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    </DossierCard>
  );
};

export default VerifiedIdentityCard;
