const notificationStatusTag = {
  PENDING: {
    color: 'warning',
    title:
      'pages.private.profileModels.profileModelList.components.links.components.cardLink.utils.notificationStatusTag.PENDING.title',
    icon: 'caf-ic_circle_hold',
  },
  NOT_INFORMED: {
    color: 'gray',
    title:
      'pages.private.profileModels.profileModelList.components.links.components.cardLink.utils.notificationStatusTag.NOT_INFORMED.title',
    icon: 'caf-ic_circle_hold',
  },
  NotificationRejectedEvent: {
    color: 'magenta',
    title:
      'pages.private.profileModels.profileModelList.components.links.components.cardLink.utils.notificationStatusTag.REJECTED.title',
    icon: 'caf-ic_circle_close',
  },
  NotificationFailedEvent: {
    color: 'red',
    title:
      'pages.private.profileModels.profileModelList.components.links.components.cardLink.utils.notificationStatusTag.FAILED.title',
    icon: 'caf-ic_circle_error',
  },
  NotificationDeliveredEvent: {
    color: 'green',
    title:
      'pages.private.profileModels.profileModelList.components.links.components.cardLink.utils.notificationStatusTag.DELIVERED.title',
    icon: 'caf-ic_circle_send',
  },
  NotificationNotDeliveredEvent: {
    color: 'gray',
    title:
      'pages.private.profileModels.profileModelList.components.links.components.cardLink.utils.notificationStatusTag.NOT_DELIVERED.title',
    icon: 'caf-ic_circle_mute',
  },
  NotificationSentToProviderEvent: {
    color: 'blue',
    title:
      'pages.private.profileModels.profileModelList.components.links.components.cardLink.utils.notificationStatusTag.SENT.title',
    icon: 'caf-ic_circle_send',
  },
  NotificationSentToDestinationEvent: {
    color: 'blue',
    title:
      'pages.private.profileModels.profileModelList.components.links.components.cardLink.utils.notificationStatusTag.SENT.title',
    icon: 'caf-ic_circle_send',
  },
};

export default notificationStatusTag;
