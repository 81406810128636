import React from 'react';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParams } from 'use-query-params';
import {
  CompanySummaryCounterCard,
  CompanyTabPane,
  CompanyTabs,
} from 'pages/private/KYBCompany/CompanyDetailsV2/components';
import { LoupeDocumentSearch } from '@combateafraude/icons/general';
import useKybMoreRelationships from 'hooks/kybMoreRelationships';
import MoreRelationshipModal from 'pages/private/KYBCompany/CompanyDetailsV2/components/BusinessIdentityRelationships/MoreRelationshipModal';
import DirectorsContent from './DirectorsContent';
import ShareHoldersContent from './ShareHoldersContent';
import OfficersContent from './OfficersContent';
import RegisteredAgentsContent from './RegisteredAgentsContent';
import MoreRelationshipsContent from './MoreRelationshipsContent';
import usePeopleBusiness from '../../hooks/UsePeopleBusiness';
import './styles.less';

const I18N_BASE_PATH =
  'pages.private.KYBCompany.pages.companyDetails.subPages.people.businessIdentity';

const BusinessIdentityComponent = ({
  peopleData,
  itemsToExport,
  render = true,
  print = false,
}) => {
  const businessIdentityId = peopleData.businessIdentity.businessIdentityId ?? '';
  const { t: translate } = useTranslation();
  const moreRelationships = useKybMoreRelationships({ businessIdentityId });

  const {
    directors,
    officers,
    shareholders,
    registeredAgents,
    summaryCounter,
  } = usePeopleBusiness({
    businessIdentityData: peopleData.businessIdentity,
  });

  const [query, setQuery] = useQueryParams({
    tab: StringParam,
    subTab: StringParam,
  });

  if (!render) return null;

  const subTabsAllowed = [
    'directors',
    'shareholders',
    'officers',
    'registeredAgents',
    'moreRelationships',
  ];

  function getActiveTab(subTab) {
    if (moreRelationships.isToBlockMoreRelationshipsSubTab(subTab)) {
      return subTabsAllowed[0];
    }
    return subTabsAllowed.includes(subTab) ? subTab : subTabsAllowed[0];
  }

  function handleTabChange(subTab) {
    if (moreRelationships.isToBlockMoreRelationshipsSubTab(subTab)) {
      moreRelationships.showModalTerm();
      return;
    }
    setQuery(() => ({ subTab }));
  }

  function handleTabClick(subTab, event) {
    if (moreRelationships.isToBlockMoreRelationshipsSubTab(subTab)) {
      event.preventDefault();
      moreRelationships.showModalTerm();
    }
  }

  function handleExpandRelationships() {
    moreRelationships.showModalToExpandRelationships();
  }

  return (
    <>
      <div className="kyb-company-summary-counter-card">
        <CompanySummaryCounterCard
          payload={summaryCounter}
          render={itemsToExport?.value}
        />
      </div>
      <MoreRelationshipModal
        visible={moreRelationships.modalTermVisible}
        onClose={moreRelationships.handleRejectTerm}
        onAccept={moreRelationships.handleAcceptTerm}
      />
      <aside className="KYB-people-sources-sayari">
        <CompanyTabs
          className="wrapper-tabs"
          defaultActiveKey="summary"
          activeKey={getActiveTab(query?.subTab)}
          onTabClick={handleTabClick}
          onChange={handleTabChange}
          destroyInactiveTabPane
          print={print}
        >
          <CompanyTabPane
            render={!print || itemsToExport?.items.directors.value === true}
            print={print}
            tab={translate(`${I18N_BASE_PATH}.tabs.directors.title`, 'Directors')}
            key="directors"
          >
            <DirectorsContent directors={directors} print={print} />
          </CompanyTabPane>
          <CompanyTabPane
            render={!print || itemsToExport?.items.shareholders.value === true}
            print={print}
            tab={translate(`${I18N_BASE_PATH}.tabs.shareholders.title`, 'Shareholders')}
            key="shareholders"
          >
            <ShareHoldersContent shareholders={shareholders} print={print} />
          </CompanyTabPane>
          <CompanyTabPane
            render={!print || itemsToExport?.items.registeredAgents.value === true}
            print={print}
            tab={translate(
              `${I18N_BASE_PATH}.tabs.registeredAgents.title`,
              'Registered agents'
            )}
            key="registeredAgents"
          >
            <RegisteredAgentsContent registeredAgents={registeredAgents} print={print} />
          </CompanyTabPane>
          <CompanyTabPane
            render={!print || itemsToExport?.items.officers.value === true}
            print={print}
            tab={translate(`${I18N_BASE_PATH}.tabs.officers.title`, 'Officers')}
            key="officers"
          >
            <OfficersContent officers={officers} print={print} />
          </CompanyTabPane>
          <CompanyTabPane
            render={!print || itemsToExport?.items?.moreRelationships?.value === true}
            print={print}
            tab={translate(
              `${I18N_BASE_PATH}.tabs.moreRelationships.title`,
              'More relationships'
            )}
            icon={<LoupeDocumentSearch />}
            key="moreRelationships"
          >
            <MoreRelationshipsContent
              peopleData={peopleData}
              handleExpandMoreRelationshps={() => handleExpandRelationships()}
              hasAcceptedExtendeRelationships={
                moreRelationships.hasAcceptedExtendRelationships
              }
              getHasMoreRelationships={() =>
                moreRelationships.setHasAcceptedExtendRelationships(false)
              }
            />
          </CompanyTabPane>
        </CompanyTabs>
      </aside>
    </>
  );
};

export default BusinessIdentityComponent;
