import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import RenderPanelContentRow from './RenderPanelContentRow';
import { PopoverRemoveValidationsProvider } from '../PopoverRemoveValidations';

const RenderPanelContent = ({
  formRef,
  allServices,
  docs,
  disabled,
  onValuesChange,
  mode,
  text,
}) => {
  const conditionToRenderCheckbox = (item) => {
    return item?.toLowerCase().includes(text?.toLocaleLowerCase());
  };

  const customOrder = ['pfCpfData', 'pfBasicData', 'pfData', 'pfPhonesAlt', 'pfPhones'];

  function customSort(arr, order) {
    return arr.sort((a, b) => {
      const aIndex = order.indexOf(a.sectionAttr);
      const bIndex = order.indexOf(b.sectionAttr);

      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });
  }

  const sortedDocs = customSort(docs, customOrder);

  return (
    <Row>
      <Col flex={1} className="pdd-horizon-45">
        {sortedDocs?.length > 0 &&
          sortedDocs.map((item) => (
            <PopoverRemoveValidationsProvider key={item.source} type="backgroundChecking">
              {(conditionToRenderCheckbox(item.description) ||
                conditionToRenderCheckbox(item.title)) && (
                <RenderPanelContentRow
                  service={item}
                  disabled={disabled}
                  formRef={formRef}
                  allServices={allServices}
                  onChange={onValuesChange}
                  mode={mode}
                />
              )}
            </PopoverRemoveValidationsProvider>
          ))}
      </Col>
    </Row>
  );
};

RenderPanelContent.propTypes = {
  mode: PropTypes.oneOf(['preview-compact', 'edit']),
  docs: PropTypes.arrayOf(PropTypes.object),
  allServices: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool.isRequired,
  onValuesChange: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

RenderPanelContent.defaultProps = {
  mode: 'edit',
  docs: undefined,
  allServices: [],
};

export default RenderPanelContent;
