import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactJsonView from 'react-json-view';

import { Modal, Button, Input } from 'antd';

import PageTitle from 'components/PageTitle';
import copyTextToClipboard from 'utils/copyTextToClipboard';
import './styles.less';
import { useAuth } from 'hooks/auth';
import { requiredDataUFAntecedentes, requiredDataUFDetran } from './formUtils';

const ModalProfileTemplateApiGuide = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [dataControllerPF, setDataControllerPF] = useState({
    antecedentes: false,
    nadaConsta: false,
    rais: false,
    cpf: false,
    detran: false,
    antt: false,
    zipCodeDetails: false,
    pfPenaltiesOnCvm: false,
    suspiciousPlaces: false,
    pfBasicData: false,
    pfData: false,
    pfCpfData: false,
    emailAgePrivate: false,
    denatranVehicleViolations: false,
    denatranDriverViolations: false,
    pfBackgroundCheckFederalPolice: false,
    pfAddressAuthentication: false,
    pfSanctionsListOFAC: false,
    pfPhoneScore: false,
    pfBiometriaUnico: false,
    simSwapAlert: false,
    pfMbsArcaMs2: false,
    phoneAnalysis: false,
    emailAnalysis: false,
    pfFinantialData: false,
    pfOccupationData: false,
    peopleComplianceBet: false,
  });

  const requireDocumentsSources = [
    'authentic_document',
    'documentscopy',
    'ocr',
    'official_data',
    'official_data_latam',
    'facematch',
  ];

  const postUrl = useMemo(
    () => `${process.env.REACT_APP_BASE_URL_CAF_API}/v1/transactions?origin=TRUST`,
    []
  );

  const { user } = useAuth();

  const headers = useMemo(() => {
    const token = user?.accountData?.products?.trust?.cafAccessToken || '';
    return {
      headers: {
        Authorization: token,
      },
    };
  }, [user]);

  const [dataControllerPJ, setDataControllerPJ] = useState({
    sintegraData: false,
    zipCodeDetails: false,
    pjCpfMei: false,
    pjPenaltiesOnCvm: false,
    suspiciousPlaces: false,
    emailAgePrivate: false,
    denatranVehicleViolations: false,
    denatranDriverViolations: false,
    pjSanctionsListOFAC: false,
  });

  const { t: translate } = useTranslation();

  const [data, setData] = useState({});

  const backgroundCheckingServices = useMemo(() => {
    if (data?.backgroundCheckingServices) {
      return Object.values(data?.backgroundCheckingServices);
    }

    if (data?.backgroundChecking?.services) {
      return data?.backgroundChecking?.services;
    }

    return [];
  }, [data]);

  const onboardingServices = useMemo(() => {
    if (data?.onboardingServices) {
      return Object.values(data?.onboardingServices);
    }

    if (data?.onboarding?.services) {
      return data?.onboarding?.services;
    }

    return [];
  }, [data]);

  useEffect(() => {
    const nadaConsta = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'pf_nada_consta'
    );
    const antecedentes = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'pf_antecedentes'
    );
    const rais = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'pf_rais'
    );
    const cpf = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'cpf'
    );
    const detran = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'detran'
    );
    const antt = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'antt'
    );
    const pfPenaltiesOnCvm = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'pf_penalties_on_cvm'
    );
    const pfBasicData = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'pf_basic_data'
    );
    const pfData = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'pf_data'
    );
    const pfCpfData = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'pf_cpf_data'
    );
    const pfAddressAuthentication = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'pf_address_authentication'
    );
    const pfBackgroundCheckFederalPolice = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'pf_background_check_federal_police'
    );
    const pfSanctionsListOFAC = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'pf_ofac_sanctions_list'
    );
    const pfPhoneScore = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'phone_trust_score'
    );
    const pfBiometriaUnico = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'pf_biometria_unico'
    );
    const simSwapAlert = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'sim_swap_alert'
    );
    const pfMbsArcaMs2 = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'pf_mbs_arca_ms2'
    );
    const phoneAnalysis = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'phone_analysis'
    );
    const emailAnalysis = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'email_analysis'
    );
    const zipCodeDetails = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'zip_code_details'
    );
    const suspiciousPlaces = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'suspicious_places'
    );
    const emailAgePrivate = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'email_age_private'
    );
    const denatranVehicleViolations = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'denatran_vehicle_violations'
    );
    const denatranDriverViolations = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'denatran_driver_violations'
    );
    const pfFinantialData = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'pf_finantial_data'
    );
    const pfOccupationData = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'pf_occupation_data'
    );

    const peopleComplianceBet = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'people_compliance_bet'
    );

    setDataControllerPF({
      nadaConsta,
      antecedentes,
      rais,
      cpf,
      detran,
      antt,
      pfPenaltiesOnCvm,
      pfBasicData,
      pfData,
      pfCpfData,
      pfAddressAuthentication,
      pfBackgroundCheckFederalPolice,
      pfSanctionsListOFAC,
      pfPhoneScore,
      pfBiometriaUnico,
      simSwapAlert,
      pfMbsArcaMs2,
      phoneAnalysis,
      emailAnalysis,
      zipCodeDetails,
      suspiciousPlaces,
      emailAgePrivate,
      denatranVehicleViolations,
      denatranDriverViolations,
      pfFinantialData,
      pfOccupationData,
      peopleComplianceBet,
    });

    const sintegraData = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'pj_sintegra_data'
    );
    const pjCpfMei = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'pj_cpf_mei'
    );
    const pjPenaltiesOnCvm = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'pj_penalties_on_cvm'
    );
    const pjSanctionsListOFAC = !!backgroundCheckingServices?.find(
      (service) => service?.source === 'pj_ofac_sanctions_list'
    );

    setDataControllerPJ({
      sintegraData,
      zipCodeDetails,
      pjCpfMei,
      pjPenaltiesOnCvm,
      suspiciousPlaces,
      emailAgePrivate,
      denatranVehicleViolations,
      denatranDriverViolations,
      pjSanctionsListOFAC,
    });
  }, [backgroundCheckingServices]);

  const requiredFields = useMemo(() => {
    let arrayFields = {};

    if (
      data?.backgroundChecking?.validations?.some(
        (validation) => validation.rule === 'qsa_consistent'
      )
    ) {
      arrayFields = {
        ...arrayFields,
        qsa: [
          {
            cpf: '',
            name: '',
          },
        ],
      };
    }

    if (data?.type === 'PJ') {
      arrayFields = {
        ...arrayFields,
        cnpj: '',
      };
    }

    if (data?.type === 'PF') {
      arrayFields = {
        ...arrayFields,
        cpf: '',
      };
    }

    if (dataControllerPF?.nadaConsta) {
      arrayFields = { ...arrayFields, issueState: '' };
    }

    if (dataControllerPF?.antecedentes) {
      arrayFields = { ...arrayFields, ...requiredDataUFAntecedentes, issueState: '' };
    }

    if (dataControllerPF?.rais) {
      arrayFields = { ...arrayFields, nit: '' };
    }

    if (dataControllerPF?.cpf) {
      arrayFields = { ...arrayFields, birthDate: '' };
    }

    if (dataControllerPF?.detran) {
      arrayFields = {
        ...arrayFields,
        ...requiredDataUFDetran,
        cnhExpirationDate: '',
      };
    }

    if (dataControllerPF?.pfSanctionsListOFAC || dataControllerPJ?.pJSanctionsListOFAC) {
      arrayFields = { ...arrayFields, name: '' };
    }

    if (dataControllerPF?.pfSanctionsListOFAC || dataControllerPJ?.pjSanctionsListOFAC) {
      arrayFields = { ...arrayFields, name: '' };
    }

    if (dataControllerPF?.pfSanctionsListOFAC || dataControllerPJ?.pjSanctionsListOFAC) {
      arrayFields = { ...arrayFields, name: '' };
    }

    if (dataControllerPJ?.sintegraData) {
      arrayFields = { ...arrayFields, uf: '' };
    }

    if (dataControllerPF?.antt || dataControllerPF.denatranVehicleViolations) {
      arrayFields = { ...arrayFields, plate: '' };
    }

    if (dataControllerPF?.zipCodeDetails || dataControllerPJ?.zipCodeDetails) {
      arrayFields = { ...arrayFields, cep: '' };
    }

    if (dataControllerPF?.emailAgePrivate || dataControllerPJ?.emailAgePrivate) {
      arrayFields = { ...arrayFields, email: '', userIp: '' };
    }

    if (dataControllerPJ?.pjCpfMei) {
      arrayFields = { ...arrayFields, cpf: '', birthDate: '' };
    }

    if (dataControllerPJ.denatranDriverViolations) {
      arrayFields = { ...arrayFields, cpf: '' };
    }

    if (dataControllerPF?.pfPenaltiesOnCvm || dataControllerPJ?.pjPenaltiesOnCvm) {
      arrayFields = { ...arrayFields, name: '' };
    }

    if (dataControllerPF?.suspiciousPlaces || dataControllerPJ?.suspiciousPlaces) {
      arrayFields = { ...arrayFields, latitude: '', longitude: '', accuracy: '' };
    }

    if (dataControllerPF?.pfBiometriaUnico) {
      arrayFields = { ...arrayFields, name: '' };
    }

    if (dataControllerPF?.pfAddressAuthentication) {
      arrayFields = { ...arrayFields, phoneNumber: '', cep: '' };
    }

    if (dataControllerPF?.pfPhoneScore) {
      arrayFields = { ...arrayFields, phoneNumber: '' };
    }

    if (dataControllerPF?.simSwapAlert) {
      arrayFields = { ...arrayFields, phoneNumber: '' };
    }

    if (dataControllerPF?.phoneAnalysis) {
      arrayFields = { ...arrayFields, phoneNumber: '' };
    }

    if (dataControllerPF?.emailAnalysis) {
      arrayFields = { ...arrayFields, email: '' };
    }

    if (dataControllerPF?.pfFinantialData) {
      arrayFields = { ...arrayFields, birthDate: '' };
    }

    if (dataControllerPF?.pfOccupationData) {
      arrayFields = { ...arrayFields, birthDate: '' };
    }

    if (dataControllerPF?.pfBackgroundCheckFederalPolice) {
      arrayFields = { ...arrayFields, name: '', motherName: '', birthDate: '' };
    }

    if (dataControllerPF?.peopleComplianceBet) {
      arrayFields = { ...arrayFields, birthDate: 'dd/MM/yyyy' };
    }

    return arrayFields;
  }, [dataControllerPF, dataControllerPJ, data]);

  const jsonData = useMemo(() => {
    const obj = {};

    if (onboardingServices.some((s) => requireDocumentsSources.includes(s?.source))) {
      obj.files = [
        {
          type: 'RG_FRONT, RG_BACK, CNH_FRONT, CNH_BACK, CRLV ou SELFIE',
          data: 'url ou base64',
        },
      ];
    }

    obj.templateId = data?.id || data?._id || '';
    obj._callbackUrl = '';
    obj.attributes = {
      ...requiredFields,
    };

    return obj;
  }, [data, onboardingServices, requireDocumentsSources, requiredFields]);

  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
    setData(null);
  }, []);

  const openModal = useCallback(
    (template, showFooterMessage = false) => {
      if (template) setData(template);
      setShowMessage(showFooterMessage);
      setModalVisible(true);
    },
    [setData]
  );

  const ProfileTemplateApiGuideModal = useMemo(
    () => (
      <Modal
        wrapClassName="caf-modal"
        closable
        closeIcon={<i className="caf-ic_close font-size-18" />}
        visible={modalVisible}
        footer={null}
        width={700}
        onCancel={closeModal}
      >
        <PageTitle
          title={translate('components.ModalProfileTemplateApiGuide.PageTitle.title')}
          subtitle={translate(
            'components.ModalProfileTemplateApiGuide.PageTitle.subtitle'
          )}
          style={{ marginBottom: 20 }}
        />

        <strong className="text-dark text-bold">
          {translate('components.ModalProfileTemplateApiGuide.label.endpoint')}
        </strong>

        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#F2F2F2',
            height: 43,
            marginTop: 10,
            marginBottom: 20,
          }}
        >
          <div
            style={{
              flex: 1,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <Button
              type="primary"
              shape="round"
              className="flex no-mrg-btm center"
              outlined="false"
              style={{ height: 26, width: 57, marginLeft: 8, marginRight: 0 }}
            >
              POST
            </Button>
            <Input
              type="text"
              className="text-dark"
              readOnly
              value={postUrl}
              bordered={false}
            />
          </div>
          <Button
            type="link"
            style={{ paddingLeft: 0 }}
            onClick={() =>
              copyTextToClipboard(
                postUrl,
                translate(
                  'components.ModalProfileTemplateApiGuide.copyButton.urlCopySuccess'
                ),
                translate(
                  'components.ModalProfileTemplateApiGuide.copyButton.urlCopyFail'
                )
              )
            }
          >
            {translate('components.ModalProfileTemplateApiGuide.copyButton.title')}
          </Button>
        </div>

        <strong className="text-dark text-bold">
          {translate('components.ModalProfileTemplateApiGuide.label.headers')}
        </strong>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            backgroundColor: '#F2F2F2',
            minHeight: 43,
            paddingTop: 10,
            paddingBottom: 10,
            marginTop: 10,
            marginBottom: 20,
          }}
        >
          <div
            style={{
              flex: 1,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              paddingLeft: 10,
              wordBreak: 'break-all',
            }}
          >
            <ReactJsonView
              name={false}
              indentWidth={2}
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              src={headers}
            />
          </div>
          <Button
            type="link"
            onClick={() =>
              copyTextToClipboard(
                JSON.stringify(headers),
                translate(
                  'components.ModalProfileTemplateApiGuide.copyButton.dataCopySuccess'
                ),
                translate(
                  'components.ModalProfileTemplateApiGuide.copyButton.dataCopyFail'
                )
              )
            }
          >
            {translate('components.ModalProfileTemplateApiGuide.copyButton.title')}
          </Button>
        </div>

        <strong className="text-dark text-bold">
          {translate('components.ModalProfileTemplateApiGuide.label.body')}
        </strong>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            backgroundColor: '#F2F2F2',
            minHeight: 43,
            paddingTop: 10,
            paddingBottom: 10,
            marginTop: 10,
          }}
        >
          <div
            style={{
              flex: 1,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              wordBreak: 'break-all',
              paddingLeft: 10,
            }}
          >
            <ReactJsonView
              name={false}
              indentWidth={2}
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              src={jsonData}
            />
          </div>
          <Button
            type="link"
            onClick={() =>
              copyTextToClipboard(
                JSON.stringify(jsonData),
                translate(
                  'components.ModalProfileTemplateApiGuide.copyButton.dataCopySuccess'
                ),
                translate(
                  'components.ModalProfileTemplateApiGuide.copyButton.dataCopyFail'
                )
              )
            }
          >
            {translate('components.ModalProfileTemplateApiGuide.copyButton.title')}
          </Button>
        </div>

        {showMessage ? (
          <PageTitle
            subtitle={translate(
              'components.ModalProfileTemplateApiGuide.ExtraPageTitle.subtitle'
            )}
          />
        ) : (
          <></>
        )}

        <div className="modal-integration-action-buttons" align="middle" justify="end">
          <Button
            type="primary"
            shape="round"
            className="flex no-mrg-btm center"
            outlined="false"
            onClick={closeModal}
          >
            {translate('components.ModalProfileTemplateApiGuide.closeButton.title')}
          </Button>
        </div>
      </Modal>
    ),
    [modalVisible, closeModal, translate, postUrl, headers, jsonData, showMessage]
  );

  return { openModal, ProfileTemplateApiGuideModal };
};

export default ModalProfileTemplateApiGuide;
