/* eslint-disable react/prop-types */

import React from 'react';
import DottedLabel from 'components/DottedLabel';
import { useTranslation } from 'react-i18next';
import { formatCPF, formatCNPJ } from '../../utils';
import ColumnInformation from '../ColumnInformation';
import { SportsRelated } from '../SportsRelated';

const I18N_BASE_PATH =
  'components.dataSourceCards.relationships.components.peopleComplianceBet.index.components.PersonsRelated';

const hasRelatedPersons = (result) => {
  const personEntities = [];
  const documentsMapped = [];

  result?.onlineBettingCompliance?.sportsExposure?.sports.forEach((sport) => {
    sport?.relatedEntities?.forEach((element) => {
      if (
        element.docNumber &&
        (element.docType === 'CPF' || element.docType === 'RG') &&
        !documentsMapped.includes(element.docNumber)
      ) {
        personEntities.push({
          docNumber: element.docNumber,
          docType: element.docType,
          relationshipLevel: element.relationshipLevel,
        });
        documentsMapped.push(element.docNumber);
      }
    });
  });
  if (!personEntities.length) return null;

  return personEntities;
};

const RelatedPersonDetails = ({ result, query }) => {
  const relatedPersons = hasRelatedPersons(result);
  const { t: translate } = useTranslation();
  return (
    <>
      <ColumnInformation
        title={translate(`${I18N_BASE_PATH}.column.title.person`)}
        fields={[
          {
            label: translate(`${I18N_BASE_PATH}.column.label.name`),
            value: result.onlineBettingCompliance.forbiddenBet.name,
            span: 8,
          },
          {
            label: translate(`${I18N_BASE_PATH}.column.label.personId`),
            value: formatCPF(query?.cpf),
            span: 4,
          },
          {
            label: translate(`${I18N_BASE_PATH}.column.label.age`),
            value: result.onlineBettingCompliance.forbiddenBet.age,
            span: 2,
          },

          {
            label: translate(`${I18N_BASE_PATH}.column.label.birthDate`),
            value: query?.birthDate,
            span: 10,
          },
          {
            label: translate(`${I18N_BASE_PATH}.column.label.isFromMinisterioDaFazenda`),
            value: result.onlineBettingCompliance.forbiddenBet.isFromMinisterioDaFazenda,
            span: 6,
          },

          {
            label: translate(`${I18N_BASE_PATH}.column.label.isBookmakerOwner`),
            value: result.onlineBettingCompliance.forbiddenBet.isBookmakerOwner,
            span: 6,
          },
          {
            label: translate(`${I18N_BASE_PATH}.column.label.motivesApostas`),
            value: result.onlineBettingCompliance.forbiddenBet.bookmakerOwnerMotives
              .length
              ? result.onlineBettingCompliance.forbiddenBet.bookmakerOwnerMotives.map(
                  (motive, index) => (
                    <div key={index} className="mrg-btm-8">
                      {`${formatCNPJ(motive.cnpj) ?? ''} - ${motive.companyName ?? ''}`}
                    </div>
                  )
                )
              : null,
            span: 24,
          },
        ]}
        noDivider={!relatedPersons?.length}
      />
      {relatedPersons && (
        <div>
          <DottedLabel
            type="danger"
            description={translate(`${I18N_BASE_PATH}.dotted.description`)}
          />
          {relatedPersons.map((person, index) => (
            <div key={index} style={{ marginTop: '24px' }}>
              <ColumnInformation
                fields={[
                  {
                    label: translate(`${I18N_BASE_PATH}.column.label.document`),
                    value: formatCPF(person.docNumber),
                    span: 4,
                  },
                  {
                    label: translate(`${I18N_BASE_PATH}.column.label.relationshipLevel`),
                    value: person.relationshipLevel
                      ? translate(
                          `components.dataSourceCards.relationships.components.peopleComplianceBet.index.relationshipLevel.value.${person.relationshipLevel}`,
                          person.relationshipLevel
                        )
                      : null,
                    span: 20,
                  },
                ]}
                noDivider
              />
            </div>
          ))}
        </div>
      )}
      {result.onlineBettingCompliance.sportsExposure.sports.map((sport, index) => (
        <div key={index} className="render-sports-background">
          <SportsRelated sport={sport} index={index} />
        </div>
      ))}
    </>
  );
};

export default RelatedPersonDetails;
